import { render, staticRenderFns } from "./AboutSocialMedia.vue?vue&type=template&id=2b9c6920&scoped=true"
import script from "./AboutSocialMedia.vue?vue&type=script&lang=ts"
export * from "./AboutSocialMedia.vue?vue&type=script&lang=ts"
import style0 from "./AboutSocialMedia.vue?vue&type=style&index=0&id=2b9c6920&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b9c6920",
  null
  
)

export default component.exports