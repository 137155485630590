<template>
  <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
    <div class="resume-content">
      <div class="mb-2 experience-name-date">
        <h2 class="experience-name">
          <span class="bubble-major" v-bind:class="{ complete: experience.status === 'complete', current: experience.status === 'current' }"></span>
          <div>{{experience.company}}</div>
        </h2>
        <span class="resume-date text-md-right experience-date">
          <span class="text-primary">{{experience.startDate}} - {{experience.endDate}}</span>
        </span>
      </div>
      <div v-for="(o, index) in experience.roles" class="mb-2">
        <div v-if="o.role" class="job-header mb-1 mt-2">
          <span class="job subheading">
            <span class="bubble-minor" v-bind:class="{ complete: o.status === 'complete', current: o.status === 'current' }"></span>
            <div>{{o.role}}</div>
          </span>
          <span class="experience-date-minor">{{o.startDate}} - {{ o.endDate }}</span>
          <ul>
            <li
              v-for="(bullet, bulletIndex) in o.bullets"
              v-bind:key="'ExperienceList' + index + 'Bullets' + bulletIndex"
            >{{bullet}}</li>
          </ul>
        </div>
        <hr v-if="index !== experience.roles.length - 1" class="m-0" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ExperienceContent',
  props: {
    experience: Object,
    index: Number
  }
}
</script>

<style scoped lang="scss">
div.resume-content {
  width: 100%;
}

.experience-name-date {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.job-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.job.subheading {
  display: flex;
  font-weight: 500;
  font-size: 2rem;
}

.job.subheading div {
  position: relative;
  left: -16px;
}

.experience-date {
  font-size: 1.5rem;
  min-width: 0 !important;
}

.experience-date-minor {
  font-size: 1.2rem;
  min-width: 0 !important;
}

.experience-name {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  display: flex;
}

.experience-name div {
  position: relative;
  left: -20px;
}

.role-name {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  display: flex;
}

.role-name div {
  position: relative;
  left: -20px;
}

.subheading {
  display: flex;
  font-weight: 500;
  font-size: 2rem;
}

.bubble-major {
  content: '';
  background-color: white;
  border: 2px solid #2196F3;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  padding-left: 0px;
  left: -35px;
  margin-top: 14px;
  position: relative;
  display: inline-block;
  align-self: start;
}

.bubble-minor {
  content: '';
  background-color: white;
  border: 2px solid #2196F3;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  padding-left: 0px;
  left: -33px;
  margin-top: 16px;
  position: relative;
  display: inline-block;
  align-self: start;
}

.complete {
  background-color: #2196F3;
}

.current {
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from { background-color: white; }
  to { background-color: #2196F3; }
}
</style>
